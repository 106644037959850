const StudentNonTeachingFeedback = [
    {
        _id : 1,
        value: 1
    },
    
    {
        _id : 2,
        value: 2
    },
    {
        _id : 3,
        value: 3
    },
    {
        _id : 4,
        value: 4
    },
]

export default StudentNonTeachingFeedback