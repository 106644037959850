const StudentTeachingFeedbackForm = [
    {
        title: "Email",
        _name: "email_id",
        type: "email",
        requiredStatus : true,
        hasValidations:true,
        validationType:'email-id',
        hasOptions: false,
        page: 1,
        fieldsPerLine:2
    },
    {
        title: "Batch Year",
        _name: "student_batch_year",
        type: "text",
        requiredStatus : true,
        hasValidations:true,
        validationType:'year',
        hasOptions: false,
        page: 1,
        fieldsPerLine:2
    },
    {
        title: "Class / Division",
        _name: "student_class_and_division",
        type: "text",
        requiredStatus : true,
        hasOptions: false,
        page: 1,
        fieldsPerLine:1
    },
    {
        title: "Name of the teacher to be evaluated",
        _name: "teacher_name",
        type: "text",
        requiredStatus : true,
        hasOptions: false,
        page: 2,
        fieldsPerLine:2
    },
    {
        title: "Designation",
        _name: "teacher_designation",
        type: "text",
        requiredStatus : true,
        hasOptions: false,
        page: 2,
        fieldsPerLine:2
    },
    {
        title: "Subject Taught",
        _name: "teaching_subject",
        type: "text",
        requiredStatus : true,
        hasOptions: false,
        page: 2,
        fieldsPerLine:1
    },
    {
        title:"Knowledge base of the teacher (as perceived by you)",
        type:"radio",
        requiredStatus : true,
        _name: "q_01",
        hasOptions: true,
        options: ["Outstanding", "Very Good", "Good" , "Average", "Poor"],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Communication skills (in terms of articulation and comprehensibility)",
        type:"radio",
        requiredStatus : true,
        _name: "q_02",
        hasOptions: true,
        options: ["Outstanding", "Very Good", "Good" , "Average", "Poor"],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Sincerity/ Commitment of the teacher",
        type:"radio",
        requiredStatus : true,
        _name: "q_03",
        hasOptions: true,
        options: [1,2,3,4,5],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Punctuality of the teacher (whether the class starts on time)",
        type:"radio",
        requiredStatus : true,
        _name: "q_04",
        hasOptions: true,
        options: [1,2,3,4,5],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Interest generated by the teacher",
        type:"radio",
        requiredStatus : true,
        _name: "q_05",
        hasOptions: true,
        options: [1,2,3,4,5],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Ability to integrate course material with environment/other issues, to provide a broader perspective",
        type:"radio",
        requiredStatus : true,
        _name: "q_06",
        hasOptions: true,
        options: ["Outstanding", "Very Good", "Good" , "Average", "Poor"],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Ability to integrate content with other courses",
        type:"radio",
        requiredStatus : true,
        _name: "q_07",
        hasOptions: true,
        options: ["Outstanding", "Very Good", "Good" , "Average", "Poor"],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Accessibility of the teacher in and out of the class (includes availability of the teacher to motivate further study and discussion outside class)",
        type:"radio",
        requiredStatus : true,
        _name: "q_08",
        hasOptions: true,
        options: [1,2,3,4,5],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Ability to design quizzes/tests/assignments/examinations and projects to evaluate students understanding of the course",
        type:"radio",
        requiredStatus : true,
        _name: "q_09",
        hasOptions: true,
        options: ["Outstanding", "Very Good", "Good" , "Average", "Poor"],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Does the teacher use I.C.T",
        type:"radio",
        requiredStatus : true,
        _name: "q_10",
        hasOptions: true,
        options: ["Yes","No"],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Overall rating ",
        type:"radio",
        requiredStatus : true,
        _name: "q_11",
        hasOptions: true,
        options: ["Outstanding", "Very Good", "Good" , "Average", "Poor"],
        page:3,
        fieldsPerLine:1
    },
    {
        title:"Give 3 reasons for nominating the teacher for the award",
        type:"textarea",
        requiredStatus : true,
        _name: "nominating_reasons",
        hasOptions: false,
        page:4,
        fieldsPerLine:1
    },

]

export default StudentTeachingFeedbackForm;